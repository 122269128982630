<!-- 医院管理 -->
<template>
	<div class="doctor_management">
	<el-row class="bread_crumbs">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/HospitalManagement' }">组织结构管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/HospitalManagement' }">医院列表</el-breadcrumb-item>
		</el-breadcrumb>
	</el-row>
	<div class="public_bg">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline"  class="demo-form-inline">
								<el-form-item label="医院全称:" prop="hospitalName">
									<el-input clearable v-model="formInline.hospitalName" placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="querys">查询</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button icon="el-icon-plus" type="primary" @click="NewAdd('新增',{})">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table  stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}" :data="HospitalPageData" style="width: 100%">
								<el-table-column
								  label="序号"
								  type="index"
								  width="50"
								  align="left">
								</el-table-column>
								<el-table-column align="left" prop="hospitalName" label="医院全称">
								</el-table-column>
								<el-table-column align="left"  label="所在地址">
									<template slot-scope="scope">
										{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.address}}
									</template>
								</el-table-column>
								<el-table-column align="left" label="操作">
									<template slot-scope="scope">
										<el-button type="text" :class="scope.row.status!=='200000' ? 'infos' :'primarys'" @click="NewAdd('编辑',scope.row)">编辑</el-button>
										<el-button type="text" :class="scope.row.status=='200000' ? 'dangers' :'primarys'" @click="NewAdd('状态',scope.row)">{{scope.row.status=='200000' ? '停用' :'启用'}}</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<el-dialog :title="title" :visible.sync="DialogVisible" width="40%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="医院全称:" prop="hospitalName">
					<el-input v-model="ruleForm.hospitalName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="省市:" prop="Provinces">
					<el-cascader  v-model="ruleForm.Provinces" clearable style="width:100%" placeholder="请选择" filterable
					 :props="{ value: 'id', label: 'executiveName'}" :options="ProvincesOptions">
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址:" prop="address">
					<el-input v-model="ruleForm.address" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="DialogVisibleSave">确 认</el-button>
			</span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		data() {
			return {
				//Height:document.documentElement.clientHeight-280,
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				ProvincesOptions:[],
				HospitalOptions:[],
				ruleForm: {},
				rules: {
					hospitalName: [
						{ required: true, message: '请输入医院全称', trigger: 'blur' }
					  ],
					  Provinces: [
						{ required: true, message: '请选择省市', trigger: 'change' }
					  ],
					  address: [
						{ required: true, message: '请输入详细地址', trigger: 'blur' }
					  ],
				},
				DialogVisible: false,
				//title
				title: "",
				//form表单查询
				formInline: {},
				//table列表
				HospitalPageData: [],
				//总条数
				total: 0,
			}
		},
		methods: {
			 //省市区接口
			async QueryProvinces(){
			    let res = await this.$Http.Provinces({});
				if (res.code == '200') {
					this.ProvincesOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//医院接口
			async QueryHospital(){
				let obj={
					menuId: this.menuCode,
				}
			    let res = await this.$Http.Hospital(obj);
				if (res.code == '200') {
					this.HospitalOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//分页接口
			async QueryHospitalQueryPage(current = 1, size = 10){
			    let info={
			  		current:current,
			  		size:size,
					hospitalName:this.formInline.hospitalName,
					menuId: this.menuCode,
			  	}
			    let res = await this.$Http.HospitalQueryPageInfo(info)
				if (res.code == '200') {
					this.HospitalPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.HospitalPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//新增接口
			async QueryHospitalInsertBy(){
			    let info={
					address:this.ruleForm.address,
			  		city:this.ruleForm.Provinces[1],
			  		province:this.ruleForm.Provinces[0],
					hospitalName:this.ruleForm.hospitalName
			  	}
			    let res = await this.$Http.HospitalInsertByInfo(info);
				this.DialogVisible=false;
				if (res.code == '200') {
					this.QueryHospitalQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//修改接口
			async QueryHospitalUpdateBy(){
			    let info={
					address:this.ruleForm.address,
			  		city:this.ruleForm.Provinces[1],
			  		province:this.ruleForm.Provinces[0],
					hospitalName:this.ruleForm.hospitalName,
					hospitalId:this.ruleForm.hospitalId,
					status:this.status
			  	}
			    let res = await this.$Http.HospitalUpdateByInfo(info);
				this.DialogVisible=false;
				if (res.code == '200') {
					this.QueryHospitalQueryPage();
					this.$message.success(res.message);
				} else {
					this.$message.error(res.message);
				}
			},
			//保存
			DialogVisibleSave() {
				 this.$refs["ruleForm"].validate((valid) => {
				  if (valid) {
					  this.title=="新增医院" ? this.QueryHospitalInsertBy() : this.QueryHospitalUpdateBy();
                  } else {
					return false;
				  }
				});
            },
			//分页组件
			pages(current, size) {
                this.QueryHospitalQueryPage(current, size);
			},
			//编辑新增
			NewAdd(val, item) {
				this.ruleForm = JSON.parse(JSON.stringify(item));
				this.ruleForm.Provinces=[item.province,item.city];
				if(val=="状态"){
					item.status=="200000" ? this.status="200001" : this.status="200000";
					this.QueryHospitalUpdateBy();
				}else{
					if(item.status=="200001"){
						this.$message.error("已停用,不可编辑");
						return false;
					}else{
						this.DialogVisible = true;
						this.title = `${val}医院`;
						this.status=item.status;
					}
					
				}
			},
			// 搜索
			querys() {
                 this.QueryHospitalQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		created() {},
		mounted() {
			//分页
			this.QueryHospitalQueryPage();
			//省市区
			this.QueryProvinces();
			//医院
			this.QueryHospital();
		}
	}
</script>

<style scoped>
</style>
